:root {
    --bottom-menu-height: 60px;

    --radius: 15px;
    --radius-5: 5px;

    --gap: 8px;
    --gap-half: calc(var(--gap) / 2);
    --gap-2: calc(var(--gap) * 2);
    --gap-3: calc(var(--gap) * 3);
    --gap-4: calc(var(--gap) * 4);
    --gap-5: calc(var(--gap) * 5);

    --padding: 14px;

    --shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.4);

    --transition: 0.3s;

    --font-roboto: 'Roboto', sans-serif;
}

$default-size: 16;