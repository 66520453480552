@import "public/style/service";

.tooltip {
  position: relative;

  &__button {
    @include text($size: inherit, $weight: inherit);
    cursor: pointer;
  }

  &__inner {
    @include text($size: 1rem);
    display: inline-block;
    position: absolute;
    bottom: 100%;
    border-radius: var(--radius);
    background: var(--background-main-darker);
    right: 0;
    opacity: 0;
    visibility: hidden;
    padding: var(--padding);
    width: 200px;
    transition: var(--transition);

    p:first-child {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  &:hover {
    .tooltip {
      &__inner {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
