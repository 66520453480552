@import "public/style/service";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(0);
  opacity: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  transition: var(--transition);

  &__outer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    top: 100%;
    left: 0;
    overflow: auto;
    padding-top: 50px;
    width: 100%;
    height: 100%;
    transition: var(--transition);
  }

  &__before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
  }

  &__content {
    position: relative;
    z-index: 10;
    border-radius: var(--radius) var(--radius) 0 0;
    background: var(--background-main);
    overflow: hidden;
    margin-top: auto;
    padding: var(--padding);
    width: 100%;
  }

  &__head {
    position: relative;
    background: var(--background-main-darker);
    margin: calc(-1 * var(--padding)) calc(-1 * var(--padding)) 0;
    padding:  var(--gap-3) var(--gap-5) var(--gap-3) var(--padding);
  }

  &__title {
    @include text(subtitle)
  }

  &__close {
    @include text($size: pxToRem(24), $color: var(--text-primary));
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: var(--gap);
    right: var(--gap);
    z-index: 10;
    border: 0;
    background: transparent;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  &__body {
    padding: var(--padding) 0;
  }

  &.opened {
    backdrop-filter: blur(3px);
    opacity: 1;

    .modal {
      &__outer {
        top: 0;
      }
    }
  }
}