.historyPage {
  &.page {
    align-items: center;
  }

  .historyList {
    margin: -14px -14px 14px;
  }

  .loader {
    margin: auto;

    &.moreLoader {
      margin: 0 auto;
    }
  }

  &__empty {
    margin: auto;
  }

  .tg-button {
    margin: 0 auto;
  }
}