@import "public/style/service";

.homePage {
  &.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100%;
  }

  &Form {
    position: fixed;
    top: -100%;
    left: -20px;
    z-index: 200;
    background: var(--background-main);
    opacity: 0;
    visibility: hidden;
    padding: 20px;
    width: calc(100% + 40px);
    height: calc(var(--app-height) + 40px);
    transition: var(--transition) 0.3s, top 0s 1s;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 5;
      background: var(--background-main);
      width: 100%;
      height: 100%;
    }

    &__inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 10;
      opacity: 0;
      padding: var(--gap-5) var(--padding) var(--padding);
      width: 100%;
      height: 100%;
      transition: var(--transition) 0.1s;
    }

    &__closeBtn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: var(--gap);
      z-index: 10;
      border: 0;
      background: transparent;
      width: 40px;
      height: 40px;
      font-size: pxToRem(40);
      color: var(--text-primary);
    }

    .homePage__title {
      @include text($name: subtitle, $size: pxToRem(22), $align: center);
      margin-bottom: var(--gap-3);
      transform: translateY(-30px);
      transition: transform var(--transition);
    }

    &__getCardBtn.button {
      top: 30px;
      margin-top: var(--gap-3);
      transition: var(--transition);
    }
    
    &__description {
      @include text(muted);
      margin: auto 0;
      padding: var(--gap-2);
      max-width: 320px;
      text-align: center;
    }

    &.opened {
      top: -20px;
      opacity: 1;
      visibility: visible;
      transition: var(--transition), top 0s;

      .homePageForm__inner {
        opacity: 1;
      }

      .homePage__title {
        transform: translateY(0);
      }

      .homePageForm__getCardBtn.button {
        top: 0;
      }
    }
  }

  &__availablePredictionsCount {
    @include text($size: pxToRem(14), $align: center, $color: var(--text-muted));
    position: absolute;
    bottom: calc(var(--bottom-menu-height) + var(--gap-3));
    left: 0;
    margin: 0;
    width: 100%;
    font-size: pxToRem(14);
  }
  
  &__description {
    @include text(subtitle);
    margin-top: 3vh;
    margin-bottom: 3vh;
    max-width: 280px;
    width: 100%;
    text-align: center;
  }

  .card.facedDown {
    margin: auto;
    height: min(40vh, 100%);
    min-height: unset;

    .card {
      &__img {
        &Outer {
          transition: opacity var(--transition);
          min-height: unset;
          height: unset;
        }

        &Inner {
          img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: calc(var(--app-height) * 0.37);
            object-fit: contain;
            transition: var(--transition);
          }
        }
      }
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 15;
    width: 100%;
  }

  &__query {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;

    .field {
      position: relative;
      z-index: 10;
      max-width: 70vw;

      &__error {
        left: -15%;
        width: 130%;
      }
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      opacity: 0;
      width: 100%;
      height: 50px;
      transition: var(--transition);
    }

    &.animation {
      .field {
        background: var(--background-main);
        box-shadow: 0 0 20px 10px var(--background-main);
      }

      &::before {
        background: linear-gradient(
                        to left,
                        #DBC087,
                        rgba(219, 192, 135, 0.3),
                        rgba(219, 192, 135, 0.6),
                        rgba(219, 192, 135, 0.2),
                        rgba(219, 192, 135, 0.4),
                        rgba(219, 192, 135, 0.8),
                        rgba(219, 192, 135, 0.4),
                        rgba(219, 192, 135, 0.2),
                        rgba(219, 192, 135, 0.6),
                        #DBC087
        );
        background-size: 200% 200%;
        opacity: 0.4;
        transform: scale(0.9) translateZ(0);
        filter: blur(15px);
        animation: fieldGlow 10s linear infinite, fieldFlash 1s;
        transition: opacity var(--transition);
      }

      @keyframes fieldFlash {
        0% {
          opacity: 0;
        }
        10% {
          opacity: 1;
        }
        100% {
          opacity: 0.4;
        }
      }

      @keyframes fieldGlow {
        0% {
          background-position: 0 50%;
        }
        100% {
          background-position: 200% 50%;
        }
      }
    }
  }

  &__error {
    position: absolute;
    left: 0;
    top: 100%;
    margin: 0;
    color: var(--tg-theme-destructive-text-color);
  }

  &__getCardBtn {
    margin-top: min(var(--gap-5), 3vh);
  }

  &.formOpened {
    .card__imgOuter {
      opacity: 0;
    }
  }
}

//@include media(sm) {
//  .homePage {
//    &__title {
//      @include text($name: title-sm);
//      margin-top: calc(var(--gap-2) * -1);
//      margin-bottom: var(--gap);
//    }
//
//    &__getCardBtn {
//      margin-top: var(--gap-2);
//    }
//  }
//}