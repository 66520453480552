@mixin defaultTheme {
    --primary: #DBC087;
    --primary-hover: #fae3b1;
    --primary-active: #d1b57b;
    --primary-02: #DBC08733;
    --primary-05: rgba(219, 192, 135, 0.5);

    --muted: #656565;
    --muted-lighter: #b8b8b8;

    --main: #1F1F1F;
    --main-reverse: #FFFFFF;
    --main-darker: #101010;

    --gradient-danger: linear-gradient(110deg, rgba(102, 34, 37, 0) 45%, rgba(102, 34, 37, 0.3) 100%);
    --gradient-valid: linear-gradient(110deg, rgba(91, 112, 97, 0) 45%, rgba(91, 112, 97, 0.3) 100%);
    --gradient-gold: linear-gradient(110deg, rgba(219, 192, 135, 0) 45%, rgba(219, 192, 135, 0.15) 100%);
}
