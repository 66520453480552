@import "public/style/service";

.field {
  display: inline-block;
  position: relative;
  margin-bottom: var(--gap-2);
  width: 100%;

  &__inner {
    position: relative;
  }

  textarea,
  input:not([type="checkbox"]),
  input:not([type="radio"]) {
    @include text;
    outline: 0 !important;
    border: 1px solid var(--border-primary-02);
    border-radius: 10px;
    background: transparent;
    width: 100%;
    height: 50px;
    text-align: center;
    transition: border-color var(--transition);

    &::placeholder {
      @include text(placeholder);
      font-size: 14px;
      font-weight: 400;
    }

    &:focus {
      border-color: var(--border-primary-05);
    }
  }

  textarea {
    resize: none;
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 5px;
  }

  &__border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__error {
    @include text($name: error);
    position: absolute;
    top: calc(100% + var(--gap-half));
    left: 0;
    width: 100%;
  }

  &.focus,
  &.notEmpty {
    .field {
      &__border {
        &::after {
          left: 0;
          width: 100%;
        }
      }
    }
  }
}