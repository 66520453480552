:root {
    --text-main: var(--main);
    --text-main-reverse: var(--main-reverse);
    --text-primary: var(--primary);
    --text-muted: var(--muted);

    --background-main: var(--main);
    --background-main-darker: var(--main-darker);
    --background-main-reverse: var(--main-reverse);
    --background-primary: var(--primary);
    --background-primary-hover: var(--primary-hover);
    --background-primary-active: var(--primary-active);
    --background-primary-02: var(--primary-02);
    --background-primary-05: var(--primary-05);
    --background-muted-lighter: var(--muted-lighter);

    --border-main: var(--main);
    --border-main-darker: var(--main-darker);
    --border-main-reverse: var(--main-reverse);
    --border-primary: var(--primary);
    --border-primary-hover: var(--primary-hover);
    --border-primary-active: var(--primary-active);
    --border-primary-02: var(--primary-02);
    --border-primary-05: var(--primary-05);
}