@import "public/style/service";

.card {
    display: grid;
    grid-template:
        'top'
        'image'
        'bottom';
    grid-template-rows: min-content 1fr min-content;
    grid-template-columns: 1fr;
    position: relative;
    width: 100%;
    min-height: 100%;
    height: auto;

    &__img {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        border: none;
        padding: var(--padding);
        font-size: 0;

        &Outer {
            grid-area: image;
            position: relative;
            min-height: calc(100vh - 350px);
            height: 100%;
        }

        &Rays {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 5;
            margin: -190px 0 0 -190px;
            width: 380px;
            height: 380px;
            animation: cardRays 120s linear infinite;
        }

        @keyframes cardRays {
            from {transform: rotate(0deg)}
            to {transform: rotate(-360deg)}
        }

        &Inner {
            position: relative;
            z-index: 10;
            border-radius: var(--radius);
            box-shadow: var(--shadow);
            overflow: hidden;
            max-width: 195px;
            width: auto;
            max-height: 100%;

            img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: calc(var(--app-height) * 0.4);
                object-fit: contain;
                transition: var(--transition);
            }
        }
    }

    &__fog {
        &Element {
            position: absolute;
            z-index: 5;
            border-radius: 50%;
            background: radial-gradient(circle, rgba(219,192,135,1) 0%, rgba(219,192,135,0) 40%);
            opacity: 0;

            @for $i from 1 through 5 {
                &:nth-child(#{$i}) {
                    top: 5%;
                    left: 15%;
                    width: 70vw;
                    height: 80vh;
                    animation: fog 8s infinite;
                    animation-delay: #{$i}s;
                }

                @keyframes fog {
                    from { opacity: 0; }
                    50% { opacity: 0.05; }
                    to { opacity: 0; }
                }
            }

            &:nth-child(1) {
                top: 5%;
                left: 15%;
            }

            &:nth-child(2) {
                top: -10%;
                left: -15%;
            }

            &:nth-child(3) {
                top: -50%;
                left: 25%;
            }

            &:nth-child(4) {
                top: -20%;
                left: 30%;
            }

            &:nth-child(5) {
                top: 10%;
                left: 45%;
            }
        }
    }

    &__textTop,
    &__textBottom {
        position: relative;
        z-index: 10;
    }

    &__textTop {
        position: relative;
        padding-bottom: min(var(--gap-2), 1vh);
        grid-area: top;
    }

    &__name {
        @include text($name: subtitle, $align: center);
        margin-bottom: var(--gap);
        padding: 0 40px;
    }

    &__description {
        //@include text(muted, $align: center);
        @include text($align: center);
    }

    &__shareBtn.button {
        position: absolute;
        top: -3px;
        right: 0;
        border-radius: 50%;
        border-color: transparent !important;
        background: transparent !important;
        padding: 0;
        width: 36px;
        height: 36px;

        .icon {
            position: relative;
            left: -1px;
            fill: var(--text-primary);
            width: 20px;
        }
    }

    &__textBottom {
        grid-area: bottom;
    }

    &__meening {
        @include text($name: title, $align: center);
        position: relative;
        margin-top: calc(var(--gap-2) * -1);
        margin-bottom: var(--gap);

        &Text {
            display: inline-block;
            position: relative;
            padding: 0 var(--gap-2);
        }

        .tooltip {
            display: inline-flex;
            position: absolute;
            top: 0;
            right: -10px;

            &__button {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 30px;
                line-height: 1;
                opacity: 0.3;
                transition: var(--transition);
            }

            &__inner {
                @include text($size: pxToRem(14));
                width: 280px;
            }

            &:hover {
                .tooltip {
                    &__button {
                        opacity: 1;
                    }
                }
            }
        }

        .icon-question {
            @include text($size: 1rem, $weight: 700);
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: var(--background-primary);
            width: 20px;
            height: 20px;
            color: var(--text-main);
            transform: scale(0.8);
        }
    }

    &__query {
        @include text($name: muted, $align: center);
        word-break: break-word;
    }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: var(--gap);
        margin: min(var(--gap-3), 2vh) auto 0;
    }

    &__moreDetailsBtn.button {
        width: 150px;
    }

    &__newPredictionBtn.button {
        width: 150px;
        font-size: pxToRem(12);

        &.withCount {
            flex-direction: column;
            font-weight: 400;

            span {
                font-size: pxToRem(14);
                font-weight: 700;
            }
        }
    }

    &__extendedDescription {
        margin-top: var(--gap-2);
        text-align: center;

        &Title {
            margin-bottom: var(--gap);
        }

        &Text {
            @include text(muted)
        }
    }

    &__family {
        margin-top: var(--gap-3);

        .cardFamily {
            display: flex;
            flex-direction: column;
            margin: 0 calc(-1 * var(--padding)) var(--gap-4);
            width: 100vw;
            cursor: pointer;

            .historyList {
                width: 100vw;
            }

            .historyElement:first-child {
                border-top: 1px solid var(--border-primary-05);
            }

            &__title {
                margin-bottom: var(--gap);
                padding: 0 var(--padding);
            }

            &__question {
                @include text(muted);
                display: inline-flex;
                overflow: hidden;
                max-width: 100%;
                text-overflow: ellipsis;
                transition: var(--transition);
            }

            &:last-child {
                margin-bottom: 0;
            }

            &:hover {
                .cardFamily {
                    &__question {
                        color: var(--text-primary);
                    }
                }
            }
        }
    }

    &.facedDown {
        .card {
            &__imgInner {
                cursor: pointer;
            }
        }
    }

    &.yes {
        .card__fogElement {
            background: radial-gradient(circle, rgb(91, 175, 115) 0%, rgba(91, 175, 115, 0) 40%);
        }
    }

    &.no {
        .card__fogElement {
            background: radial-gradient(circle, rgb(204, 42, 49) 0%, rgba(204, 42, 49, 0) 40%);
        }
    }
}

html[lang="ru"] {
    .card {
        &__newPredictionBtn.button {
            width: 170px;
        }
    }
}