@import "public/style/service";

.settingsPage {
  &__element {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 20px;
    width: 100%;
    min-height: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__lang {
    @include text($decoration: underline, $color: var(--text-primary));
    margin-left: auto;
    cursor: pointer;
    transition: 0.3s;
    
    &:hover {
      text-decoration: none;
    }
  }

  &__getStarsBtn {
    margin: var(--gap) auto;

    &.button {
      width: 150px;
    }
  }

  .settingsMorePredictions,
  .settingsShare {
    width: 100%;

    .button {
      margin-left: auto;
      width: 110px;
      height: 40px;
    }
  }

  .settingsShare {
    .settingsPage__element {
      margin-bottom: 0;
    }

    .muted {
      @include text($size: pxToRem(11), $color: var(--text-muted));
      margin-top: var(--gap);
    }
  }

  .settingsUnlimited {
    justify-content: center;
    margin-top: var(--gap-4);
    width: 100%;
  }

  .settingsDivider {
    margin: var(--gap-3) auto;
    background: var(--background-primary-05);
    width: 50%;
    height: 1px;
  }

  .settingsTG {
    display: flex;
    flex-direction: column;
    gap: var(--gap-2);
    
    a {
      color: var(--text-primary);
      
      &:hover {
        text-decoration: none;
      }
    }
  }
}