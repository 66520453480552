@import "../public/style/themes/default.scss";
@import "../public/style/service.scss";

:root {
  @include defaultTheme;
  --default-font-size: #{$default-size}px;
  --tg-theme-header-bg-color: #ffffff;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;

  //color: var(--tg-theme-text-color);
  //background-color: var(--tg-theme-bg-color);
  color: var(--text-primary);

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

//[theme="dark"] {
//	@include darkTheme;
//}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;
  outline: none;
  margin: 0;
  padding: 0;
}

html,
body {
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: var(--app-height);
  touch-action: manipulation;
}

html {
  font-family: var(--font-roboto);
  font-size: var(--default-font-size);
}

body {
  @include text();
  background: var(--background-main);
}

.App {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow-y: auto;
  height: var(--app-height);

  .footer {
    position: fixed;
    top: calc(var(--app-height) - var(--padding));
    left: var(--padding);
    right: var(--padding);
    z-index: 10;
    opacity: 1;
    transform: translateY(-100%);
    transition: transform var(--transition), opacity var(--transition);
  }

  &.footerHidden {
    .footer {
      opacity: 0;
      transform: translateY(0);
    }
  }
}

img {
  width: 100%;
  height: auto;
}

a {
  transition: color var(--transition), opacity var(--transition);
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.muted {
  @include text(muted);
}

.page {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  z-index: 5;
  overflow: hidden;
  padding: var(--padding) var(--padding) calc(min(var(--bottom-menu-height), 10vh) + var(--padding) * 2);;
}

.button {
  @include text($name: button);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid var(--border-primary);
  border-radius: var(--radius);
  background: var(--background-primary);
  padding: 0 var(--padding);
  width: 220px;
  height: 50px;
  max-width: 100%;
  cursor: pointer;
  transition: var(--transition);

  .loader {
    width: 30px;
  }

  &:hover {
    border-color: var(--border-primary-hover);
    background: var(--background-primary-hover);
  }

  &:active {
    border-color: var(--background-primary-active);
    background: var(--background-primary-active);
    transform: translateY(1px);
    transition: 0s;
  }

  &:disabled {
    border-color: var(--background-muted-lighter) !important;
    background: var(--background-muted-lighter) !important;
    color: var(--text-muted);
    transform: none !important;
    cursor: default;
  }
}

@include media(sm, height-sm) {
  .button {
    width: 180px;
    height: 40px;
  }
}