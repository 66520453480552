.loader {
  margin: auto;
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background:
          radial-gradient(farthest-side,var(--background-primary) 94%,#0000) top/8px 8px no-repeat,
          conic-gradient(#0000 30%,var(--background-primary));
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation: l13 1s infinite linear;

  &.dark {
    background:
            radial-gradient(farthest-side,var(--background-main-darker) 94%,#0000) top/8px 8px no-repeat,
            conic-gradient(#0000 30%,var(--background-main-darker));
  }
}
@keyframes l13{
  100%{transform: rotate(1turn)}
}