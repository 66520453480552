@import "public/style/service";

.historyList {
  display: flex;
  flex-direction: column;
  width: calc(100% + 14px * 2);
}

.historyElement {
  @include text($decoration: none, $color: var(--text-primary));
  display: inline-flex;
  align-items: center;
  gap: var(--gap-2);
  position: relative;
  border-bottom: 1px solid var(--border-primary-05);
  width: 100%;
  padding: 14px;
  transition: 0.3s;

  &__text {
    display: inline-flex;
    flex-direction: column;
    gap: var(--gap-2);
    position: relative;
    z-index: 10;
    width: calc(100% - var(--gap-2) - 45px);
  }
  
  &__date {
    @include text($name: muted, $weight: 500);
  }

  &__question {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  &__card {
    position: relative;
    z-index: 10;
    margin-left: auto;
    width: 45px;
    height: 79px;
    transition: var(--transition);
    font-size: 0;

    img {
      border-radius: var(--radius-5);
      box-shadow: var(--shadow);
    }
  }

  &.positive,
  &.negative,
  &.maybe {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      width: 110%;
      height: 100%;
      transition: var(--transition);
    }

    &:hover {
      &::before {
        left: -10%;
      }
    }
  }

  &.positive {
    &::before {
      background: var(--gradient-valid);
    }
  }

  &.negative {
    &::before {
      background: var(--gradient-danger);
    }
  }

  &.maybe {
    &::before {
      background: var(--gradient-gold);
    }
  }

  &:hover {
    .historyElement {
      &__card {
        transform: scale(1.05);
      }
    }
  }
}