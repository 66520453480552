@import "public/style/service";

.buyPredictionModal {
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: var(--padding);
    margin: var(--gap-3) 0 0;
    width: 100%;
  }
  
  &__or {
    margin: var(--padding) 0 var(--gap);
    text-align: center;
  }

  &__btn {
    order: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    width: calc((100% / 3) - (var(--padding) - (var(--padding) / 3)));
    cursor: pointer;
    transition: var(--transition);

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 5%;
      left: 5%;
      z-index: 5;
      border: 1px solid var(--border-primary-02);
      border-radius: var(--radius);
      width: calc(90% - 2px);
      height: calc(90% - 2px);
      transition: var(--transition);
    }

    &Inner {
      position: relative;
      z-index: 10;
      padding: var(--gap-4) var(--padding) var(--gap-3);
    }
    
    &Count {
      @include text($size: pxToRem(12), $align: center);
      margin-bottom: var(--gap-2);

      span {
        @include text($size: pxToRem(20), $weight: 700);
      }
    }

    &Stars {
      @include text(muted, $align: center);
      white-space: nowrap;

      span {
        @include text($size: pxToRem(20), $weight: 700);
      }

      .icon {
        position: relative;
        top: 3px;
        fill: var(--text-primary);
        margin-right: var(--gap-half);
        width: 16px;
      }
    }

    &.premium {
      order: 999;
      overflow: visible;

      .buyPredictionModal__btnUnlimitedBadge {
        @include text($size: pxToRem(10), $weight: 700, $transform: uppercase, $color: var(--text-main));
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 11px;
        left: -30px;
        background: var(--background-primary);
        width: 100%;
        height: 20px;
        transform: rotate(-45deg);
      }

      &::before {
        background: var(--background-main);
        border-width: 2px;
        border-color: var(--background-primary-05);
        width: calc(90% - 4px);
        height: calc(90% - 4px);
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          to left,
          #DBC087,
          rgba(219, 192, 135, 0.3),
          rgba(219, 192, 135, 0.6),
          rgba(219, 192, 135, 0.2),
          rgba(219, 192, 135, 0.4),
          rgba(219, 192, 135, 0.8),
          rgba(219, 192, 135, 0.4),
          rgba(219, 192, 135, 0.2),
          rgba(219, 192, 135, 0.6),
          #DBC087
        );
        background-size: 200% 200%;
        opacity: 0.8;
        transform: scale(0.9) translateZ(0);
        filter: blur(15px);
        animation: fieldGlow 10s linear infinite, fieldFlash 1s;
      }

      @keyframes fieldFlash {
        0% {
          opacity: 0;
        }
        10% {
          opacity: 1;
        }
        100% {
          opacity: 0.8;
        }
      }

      @keyframes fieldGlow {
        0% {
          background-position: 0 50%;
        }
        100% {
          background-position: 200% 50%;
        }
      }

      .buyPredictionModal__btnCount {
        @include text($size: pxToRem(12), $weight: 700);
        margin-bottom: var(--gap-3);
      }
    }

    &.popular {
      &::before {
        top: 2px;
        left: 2px;
        border-color: var(--background-primary-05);
        width: calc(100% - 4px);
        height: calc(100% - 4px);
      }

      .buyPredictionModal {
        &__btn {
          &PopularBadge {
            @include text($size: pxToRem(10), $weight: 700, $transform: uppercase, $color: var(--text-main));
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 12px;
            left: -31px;
            background: var(--background-primary);
            width: 100%;
            height: 20px;
            transform: rotate(-45deg);
          }
        }
      }
    }

    &:hover::before {
      background: var(--background-main-darker);
    }

    &.active {
      transform: translateY(-5px);

      &::before {
        border-color: var(--border-primary);
        background: var(--background-main-darker);
      }

      &.premium {
        &::before,
        &::after {
          transform: scale(1.06);
        }
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: var(--gap-3);
    width: 100%;

    .button {
      width: 220px;
    }
  }

  &__share {
    &Inner {
      display: flex;
      gap: var(--gap-2);
    }
  //  display: flex;
  //  flex-direction: column;
  //
  //  .button {
  //    margin: var(--gap-2) auto;
  //  }
  //

    .button {
      margin: var(--gap) auto 0;
      //width: 110px;
    }

    .muted {
      @include text($size: pxToRem(11), $align: center, $color: var(--text-muted));
      margin-top: var(--gap);
    }
  }
}

.macos,
.ios,
.ipados {
  .buyPredictionModal {
    &__btn {
      &.premium {
        &::after {
          top: 10%;
          left: 10%;
          opacity: 1;
          width: 80%;
          height: 80%;
          filter: blur(20px);
          animation: fieldFlash 1s;
        }
      }
    }
  }
}