@import "public/style/service";

.footer {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: var(--radius);
    background-color: var(--background-main-darker);
    height: min(var(--bottom-menu-height), 10vh);

    nav {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: var(--padding);
        width: 100%;
    }

    &__link {
        @include text($weight: 500, $align: center, $decoration: none);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid transparent;
        padding: var(--gap-half);
        opacity: 0.5;
        height: 32px;

        &:hover {
            opacity: 1;
        }

        &.active {
            border-bottom: 1px solid var(--border-primary);
            opacity: 1;
        }
    }
}

html[lang="ru"] {
    .footer {
        &__link {
            @include text($size: pxToRem(13))
        }
    }
}