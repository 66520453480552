@mixin text(
  $name: false,
  $lh: false,
  $size: false,
  $weight: false,
  $align: false,
  $decoration: false,
  $transform: false,
  $color: false
) {
  line-height: 1.2;
  font-family: var(--font-roboto);
  font-size: 1rem;
  font-weight: 400;
  color: var(--text-primary);

  @if ($name) {
    @if ($name == title or $name == h1) {
      font-size: pxToRem(48);
      font-weight: 700;
      color: var(--text-primary);
    }

    @if ($name == title-sm or $name == h1-sm) {
      font-size: pxToRem(30);
      font-weight: 700;
      color: var(--text-primary);
    }

    @if ($name == subtitle or $name == h2) {
      font-size:  pxToRem(24);
      font-weight: 700;
      color: var(--text-primary);
    }

    @if ($name == subtitle-sm or $name == h2-sm) {
      font-size: pxToRem(18);
      font-weight: 700;
      color: var(--text-primary);
    }

    @if ($name == muted) {
      font-size: pxToRem(14);
      color: var(--text-muted);
    }

    @if ($name == placeholder) {
      opacity: 0.5;
      font-size: pxToRem(14);
      color: var(--text-muted);
    }

    @if ($name == error) {
      opacity: 0.5;
      font-size: pxToRem(13);
      text-align: center;
      color: var(--text-muted);
    }

    @if ($name == main) {
      color: var(--text-main);
    }

    @if ($name == button) {
      font-size: pxToRem(14);
      font-weight: 700;
      text-align: center;
      text-decoration: none;
      color: var(--text-main);
    }

    @if ($name == button-sm) {
      font-size: pxToRem(14);
      font-weight: 700;
      text-align: center;
      text-decoration: none;
      color: var(--text-main);
    }
  }

  @if ($lh) {
    line-height: $lh;
  }

  @if ($size) {
    font-size: $size;
  }

  @if ($weight) {
    font-weight: $weight;
  }

  @if ($color) {
    color: $color;
  }

  @if ($align) {
    text-align: $align;
  }

  @if ($decoration) {
    text-decoration: $decoration;
  }

  @if ($transform) {
    text-transform: $transform;
  }
}

@mixin media-sm {
  @media (max-width: 319px) and (orientation: portrait) { @content }
}

@mixin media-height-sm {
  @media (max-height: 479px) { @content }
}

@mixin media($keys...) {
  @each $key in $keys {
    @if ($key == sm) {@include media-sm {@content}}
    @if ($key == height-sm) {@include media-height-sm {@content}}
    @else {@media #{$key} {@content}}
  }
}